import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="ブランドカラー - カラー" mdxType="SEO" />
    <PageTitle title="ブランドカラー" enTitle="Brand Color" mdxType="PageTitle" />
    <h2 {...{
      "id": "概要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%A6%82%E8%A6%81",
        "aria-label": "概要 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`概要`}</h2>
    <p>{`ブランドカラーはAmebaらしさの視覚的表現の基調となるカラーパレットです。サイバーエージェントから継承されたAmebaの伝統的な緑をアクセントに、白やグレーといった無彩色の比重を強くした組み合わせを推奨しています。`}</p>
    <p>{`これらはプロダクト以外への用途に使うことができます。一方プロダクトにおいてはコントラスト比が十分に満たせない場合があるため、UIのカラーとして使うことは原則避けるようにしてください。`}</p>
    <h2 {...{
      "id": "brand-colors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#brand-colors",
        "aria-label": "brand colors permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Brand Colors`}</h2>
    <p>{`ブランドカラーはAmebaGreen,Black,white,yellowGreenです。これらは、すべてのブランドコミュニケーション全体で、一貫性を提供するために使用します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/aee7e6af97697ec16452796095d857e8/88820/brandcolor-brand.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.24999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/aee7e6af97697ec16452796095d857e8/2aaaf/brandcolor-brand.webp 160w", "/static/aee7e6af97697ec16452796095d857e8/85e47/brandcolor-brand.webp 320w", "/static/aee7e6af97697ec16452796095d857e8/75198/brandcolor-brand.webp 640w", "/static/aee7e6af97697ec16452796095d857e8/691bc/brandcolor-brand.webp 960w", "/static/aee7e6af97697ec16452796095d857e8/223e5/brandcolor-brand.webp 1280w", "/static/aee7e6af97697ec16452796095d857e8/25351/brandcolor-brand.webp 2564w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/aee7e6af97697ec16452796095d857e8/69538/brandcolor-brand.png 160w", "/static/aee7e6af97697ec16452796095d857e8/72799/brandcolor-brand.png 320w", "/static/aee7e6af97697ec16452796095d857e8/6af66/brandcolor-brand.png 640w", "/static/aee7e6af97697ec16452796095d857e8/d9199/brandcolor-brand.png 960w", "/static/aee7e6af97697ec16452796095d857e8/21b4d/brandcolor-brand.png 1280w", "/static/aee7e6af97697ec16452796095d857e8/88820/brandcolor-brand.png 2564w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/aee7e6af97697ec16452796095d857e8/6af66/brandcolor-brand.png",
              "alt": "Brand Colorsのカラーパレット",
              "title": "Brand Colorsのカラーパレット",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "expressive-colors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#expressive-colors",
        "aria-label": "expressive colors permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Expressive Colors`}</h2>
    <p>{`表現力豊かなカラーは季節、イベントなどのキャンペーンのトレンドに合わせイラストや、写真と合わせて使用します。ブランドカラーのグリーンとの共存を考え配色します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cb01b27b070f462a4500ff40830bbad5/88820/brandcolor-expressive.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.50000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cb01b27b070f462a4500ff40830bbad5/2aaaf/brandcolor-expressive.webp 160w", "/static/cb01b27b070f462a4500ff40830bbad5/85e47/brandcolor-expressive.webp 320w", "/static/cb01b27b070f462a4500ff40830bbad5/75198/brandcolor-expressive.webp 640w", "/static/cb01b27b070f462a4500ff40830bbad5/691bc/brandcolor-expressive.webp 960w", "/static/cb01b27b070f462a4500ff40830bbad5/223e5/brandcolor-expressive.webp 1280w", "/static/cb01b27b070f462a4500ff40830bbad5/25351/brandcolor-expressive.webp 2564w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/cb01b27b070f462a4500ff40830bbad5/69538/brandcolor-expressive.png 160w", "/static/cb01b27b070f462a4500ff40830bbad5/72799/brandcolor-expressive.png 320w", "/static/cb01b27b070f462a4500ff40830bbad5/6af66/brandcolor-expressive.png 640w", "/static/cb01b27b070f462a4500ff40830bbad5/d9199/brandcolor-expressive.png 960w", "/static/cb01b27b070f462a4500ff40830bbad5/21b4d/brandcolor-expressive.png 1280w", "/static/cb01b27b070f462a4500ff40830bbad5/88820/brandcolor-expressive.png 2564w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/cb01b27b070f462a4500ff40830bbad5/6af66/brandcolor-expressive.png",
              "alt": "Expressive Colorsのカラーパレット",
              "title": "Expressive Colorsのカラーパレット",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      